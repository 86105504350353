<template>
  <section>
    <navi-bar>
      {{$t('front.mypage.exclusive')}}
    </navi-bar>
    <div class="ytab">
      <div class="">
        <a :class="{'on': selectType === 'summary'}" @click="selectType = 'summary'"><span>{{$t('front.stributor.strStatus')}}</span></a>
        <a :class="{'on': selectType === 'rate'}" @click="selectType = 'rate'"><span>{{$t('front.stributor.totalList')}}</span></a>
        <a :class="{'on': selectType === 'detail'}" @click="selectType = 'detail'"><span>{{$t('front.stributor.myDetail')}}</span></a>
        <!--a :class="{'on': selectType === 'all'}" @click="selectType = 'all'"><span>게임별 베팅 합계</span></a>
        <a :class="{'on': selectType === 'member'}" @click="selectType = 'member'"><span>{{$t('front.stributor.onUsers')}}</span></a>
        <a :class="{'on': selectType === 'recommand'}" @click="selectType = 'recommand'"><span>{{$t('front.stributor.recommendUser')}}</span></a-->
      </div>
    </div>
    <!--div class="stribuname"><span>{{userData.memNick}}</span> 님의 총판 정보입니다.</div-->

    <div v-if="selectType === 'summary'" class="stributorwrap mb100" >
      <a class="saveBtn" @click="onChangePartnerAddPopup" v-if="userData.partnerLevel !== 'PTN_5'">{{$t('front.stributor.bottommake')}}</a>
      <ul>
        <li>
          <span><!--img src="@/assets_mobile/img/allUserso.svg" alt=""-->{{$t('front.stributor.Alluser')}}</span>
          <span><em>{{thousand(summary  && summary['COUNT(*)'] || 0)}}</em>{{$t('front.stributor.person')}}</span>
        </li>
        <li>
          <span><!--img src="@/assets_mobile/img/setting_completeo.svg" alt=""-->{{$t('front.stributor.rateComplet')}}</span>
          <span><em>{{thousand( (summary  && summary['COUNT(*)'] || 0) - (summary  && summary.notSetCnt || 0) )}}</em>건</span>
        </li>
        <li>
          <span><!--img src="@/assets_mobile/img/setting_incompleteo.svg" alt=""-->{{$t('front.stributor.rateIncomplete')}}</span>
          <span><em>{{thousand(summary  && summary.notSetCnt || 0)}}</em>건</span>
        </li>
        <li>
          <span><!--img src="@/assets_mobile/img/pointo.svg" alt=""-->{{$t('front.stributor.allPoint')}}</span>
          <span><em>{{thousand(summary  && summary.allpoinAmt || 0)}}</em>P</span>
        </li>
        <li>
          <span><!--img src="@/assets_mobile/img/usePointo.svg" alt=""-->{{$t('front.stributor.usePoint')}}</span>
          <span><em>{{thousand(summary  && summary.usePoinAmt || 0)}}</em>P</span>
        </li>
        <li>
          <span><!--img src="@/assets_mobile/img/currentPointo.svg" alt=""-->{{$t('front.stributor.myPoint')}}</span>
          <span><em>{{thousand(summary  && summary.pointAmt || 0)}}</em>P</span>
        </li>
      </ul>
      <div v-if="make" class="modalBg">
          <div class="moveWrap">
             <a @click="onChangePartnerAddPopup" class="close"><img src="@/assets/img/icon_cancel.png" /></a>
             <h4>파트너 정보</h4>
             <table>
                <colgroup>
                   <col width="30%"/>
                   <col width="70%"/>
                </colgroup>
               <tr>
                 <th>아이디</th>
                 <td><input class="in" type="text"  v-model="newPartner.memId" autocomplete="off" role="presentation"/></td>
               </tr>
               <tr>
                 <th>비밀번호</th>
                 <td><input class="in" type="password"  v-model="newPartner.memPass" autocomplete="off"/></td>
               </tr>
               <tr>
                 <th>닉네임</th>
                 <td><input class="in" type="text"  v-model="newPartner.memNick"/></td>
               </tr>
               <tr>
                 <th>출금 비밀번호</th>
                 <td><input class="in" type="password" v-model="newPartner.cashOutPass"/></td>
               </tr>
               <tr>
                 <th>생성파트너등급</th>
                 <td>{{newPartnerLevel.codeName}}</td>
               </tr>
               <tr>
                 <th>계좌정보</th>
                 <td>
                   <bank-list :className="'in'" @onChange="onChangeBank"></bank-list>
                   <input class="in" type="text" v-model="newPartner.bankAcc"/>
                 </td>
               </tr>
               <tr>
                 <th>이름</th>
                 <td><input class="in" type="text" v-model="newPartner.memName"/></td>
               </tr>
               <tr>
                 <th>전화번호</th>
                 <td><input class="in" type="number" v-model="newPartner.memPhone"/></td>
               </tr>
             </table>
             <h4>게임별 요율설정</h4>
             <table>
               <tr>
                 <th :colspan="Object.keys(newPartner.rateGroup).length">{{$t('front.stributor.roll')}}(%)</th>
               </tr>
               <tr>
                 <th v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
                   {{$t(`front.gnb.${key}`)}}
                 </th>
               </tr>
                <tr>
                  <td v-for="(item) in newPartner.rateGroup" v-bind:key="item">
                    <a class="rbnt">최대값 : {{item.maxPointRate}}</a>
                    <a class="rbnt">최소값 : {{item.minPointRate}}</a>
                  </td>
                </tr>
                <tr>
                  <td v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
                    <input type="text" v-model="item[`${key}PR`]">
                  </td>
                </tr>
             </table>
             <table>
               <tr>
                 <th :colspan="Object.keys(newPartner.rateGroup).length">{{$t('front.stributor.lose')}}(%)</th>
               </tr>
               <tr>
                 <th v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
                   {{$t(`front.gnb.${key}`)}}
                 </th>
               </tr>
                <tr>
                  <td v-for="(item) in newPartner.rateGroup" v-bind:key="item">
                    <a class="rbnt">최대값 : {{item.maxLoseRate}}</a>
                    <a class="rbnt">최소값 : {{item.minLoseRate}}</a>
                  </td>
                </tr>
                <tr>
                  <td v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
                    <input type="text" v-model="item[`${key}LR`]">
                  </td>
                </tr>
             </table>
           <a class="btn" @click="onAddPartnerSubmit">{{$t('front.stributor.save')}}</a>
         </div>
      </div>
    </div><!-- 총판현황 -->

    <div v-if="selectType === 'rate'" class="rollingwrap mb100" >
      <!--retail-rate-mobile :rate="rate" /-->
      <date-filter-mobile :default-day="-1" :retail="true" @search="loadMainTable"
                          @update="onChangeDateTable"
                          :startDate="mainTableDate.startDate"
                          :endDate="mainTableDate.endDate"
                          :isOldYn="true"
                          :id="'main-date-checkbox'"
                          @setOldYn="setOldYn" :oldYn="reqData.oldYn"
      />
      <ul class="strbtn mb20">
         <li>{{$t('front.common.deposit')}}<span>{{thousand(detailInfo.inCashAmt || 0)}}</span></li>
         <li>{{$t('front.common.withdrawal')}}<span>{{thousand(detailInfo.outCashAmt || 0)}}</span></li>
         <li>{{$t('front.common.depositWithdrawal')}}<span>{{thousand(detailInfo.bonusAmt || 0)}}</span></li>
      </ul>
      <ul class="strbtn mb20"><li>{{$t('front.stributor.m22')}}<span>{{thousand(detailInfo.bonusAmt || 0)}}</span></li>
         <li>{{$t('front.stributor.m23')}}<span>{{thousand((detailInfo.inCashAmt || 0) - (detailInfo.outCashAmt || 0) )}}</span></li>
         <li>{{$t('front.stributor.winlose')}}: <span>{{thousand(detailInfo.bonusAmt || 0)}}</span></li>
      </ul>
      <a class="saveBtn" @click="move=!move">{{$t('front.stributor.move')}}</a>
      <div class="strTableWrap">
         <div>
           <retail-main-table :list="list" :date="mainTableDate" :table="'mobileMain'"/>
         </div>
      </div>

      <div v-if="move" class="moveWrap">
          <a @click="move=!move" class="close"><img src="@/assets/img/icon_cancel.png" /></a>
          <h4>내요율</h4>
          <table>
            <tr>
              <th colspan="3">{{$t('front.stributor.roll')}}(%)</th>
              <th colspan="3">{{$t('front.stributor.lose')}}(%)</th>
            </tr>
            <tr>
              <th v-if="gameCount['casino']">{{$t('front.gnb.casino')}}</th>
              <th v-if="gameCount['slot']">{{$t('front.gnb.slot')}}</th>
              <th v-if="gameCount['minigame'] || gameCount['mini game']">{{$t('front.gnb.minigame')}}</th>
              <th v-if="gameCount['casino']">{{$t('front.gnb.casino')}}</th>
              <th v-if="gameCount['slot']">{{$t('front.gnb.slot')}}</th>
              <th v-if="gameCount['minigame'] || gameCount['mini game']">{{$t('front.gnb.minigame')}}</th>
            </tr>
            <tr>
              <td v-if="gameCount['casino']">{{myRate.casinoPR}}</td>
              <td v-if="gameCount['slot']">{{myRate.slotPR}}</td>
              <td v-if="gameCount['minigame'] || gameCount['mini game']">{{myRate.miniPR}}</td>
              <td v-if="gameCount['casino']">{{myRate.casinoLR}}</td>
              <td v-if="gameCount['slot']">{{myRate.slotLR}}</td>
              <td v-if="gameCount['minigame'] || gameCount['mini game']">{{myRate.miniLR}}</td>
            </tr>
          </table>
          <h4>하부회원 요율조정</h4>
          <div class="scroll">
            <table>
              <tr>
                <th rowspan="2">{{$t('front.common.memId')}}</th>
                <th rowspan="2">{{$t('front.common.nickName')}}</th>
                <th :colspan="Object.keys(commonCodeByOrder).length">{{$t('front.stributor.roll')}}(%)</th>
                <th :colspan="Object.keys(commonCodeByOrder).length">{{$t('front.stributor.lose')}}(%)</th>
              </tr>
              <tr>
                <th v-if="gameCount['casino']">{{$t('front.gnb.casino')}}</th>
                <th v-if="gameCount['slot']">{{$t('front.gnb.slot')}}</th>
                <th v-if="gameCount['minigame'] || gameCount['mini game']">{{$t('front.gnb.minigame')}}</th>
                <th v-if="gameCount['casino']">{{$t('front.gnb.casino')}}</th>
                <th v-if="gameCount['slot']">{{$t('front.gnb.slot')}}</th>
                <th v-if="gameCount['minigame'] || gameCount['mini game']">{{$t('front.gnb.minigame')}}</th>
              </tr>
              <template v-if="myChildrenRate.length">
                <template v-for="item in myChildrenRate" :key="item.memId">
                  <tr>
                    <td>{{item.memId}}</td>
                    <td>{{item.memNick}}</td>
                    <td v-if="gameCount['casino']"><input @change="onChangeChildrenRate(item)" :class="{'error': item.casinoPRError}" type="text" v-model="item.casinoPR"></td>
                    <td v-if="gameCount['slot']"><input @change="onChangeChildrenRate(item)" :class="{'error': item.slotPRError}" type="text" v-model="item.slotPR"></td>
                    <td v-if="gameCount['minigame']"><input @change="onChangeChildrenRate(item)" :class="{'error': item.miniPRError}" type="text" v-model="item.miniPR"></td>
                    <td v-if="gameCount['casino']"><input @change="onChangeChildrenRate(item)" :class="{'error': item.casinoLRError}" type="text" v-model="item.casinoLR"></td>
                    <td v-if="gameCount['slot']"><input @change="onChangeChildrenRate(item)" :class="{'error': item.slotLRError}" type="text" v-model="item.slotLR"></td>
                    <td v-if="gameCount['minigame']"><input @change="onChangeChildrenRate(item)" :class="{'error': item.miniLRError}" type="text" v-model="item.miniLR"></td>
                  </tr>
                </template>
              </template>
            </table>
          </div>
          <p>하부회원의 최대 요율은 내 상위요율을 넘을 수 없습니다. 최소요율은 해당회원의 하부 최대 요율보다 낮을 수 없습니다.</p>
        <a class="btn" @click="onUpdateChildrenRate">{{$t('front.stributor.save')}}</a>
      </div>

    </div><!-- 롤링요율 -->

    <div v-if="selectType === 'detail'" class="mydetailwrap" >
      <date-filter-mobile :retail="true" @search="loadList"
                          @update="onChangeDate"
                          :startDate="searchDate.startDate"
                          :endDate="searchDate.endDate"
      />
      <div class="mydetailTop">
        <ul class="havepointchng">
          <li class="havepoint">{{$t('front.common.total')}}</li>
          <!--li><a>{{$t('front.stributor.calcReserve')}}</a></li-->
        </ul>
        <p class="mydetailTotal">{{thousand((detailInfo.inCashAmt || 0) - (detailInfo.outCashAmt || 0) )}}</p>
        <span class="prvpoint">{{$t('front.common.bonus')}} : {{thousand(detailInfo.bonusAmt || 0)}}</span>
        <ul class="havepointwrap">
          <li><em>{{$t('front.common.deposit')}}</em><p><span>{{thousand(detailInfo.inCashAmt || 0)}}</span></p></li>
          <li><em>{{$t('front.common.withdrawal')}}</em><p><span>{{thousand(detailInfo.outCashAmt || 0)}}</span></p></li>
        </ul>
      </div>

<!--      <retail-detail-mobile :list="detailList"/>-->
    </div><!-- 나의상세내역 -->

<!--    <div v-if="selectType === 'all'" class="rollingwrap mb100">
      <date-filter-mobile :default-day="-1" :retail="true" @search="loadMainTable"
                          @update="onChangeDateTable"
                          :startDate="mainTableDate.startDate"
                          :endDate="mainTableDate.endDate"
      />
      <div class="strTableWrap">
         <div>
           <table class="strTable">
             <colgroup>
               <col width="10%">
               <col width="13%">
               <col width="11%">
               <col width="11%">
               <col width="11%">
               <col width="11%">
               <col width="11%">
               <col width="11%">
               <col width="11%">
             </colgroup>
             <tr>
              <th>세부</th>
              <th>게임</th>
              <th>총베팅</th>
              <th>총당첨</th>
              <th>베팅손익</th>
              <th>하위 롤링P</th>
              <th>하위 루징</th>
              <th>내 롤링P</th>
              <th>내 루징</th>
            </tr>
            <tr>
                <td @click="moret=!moret">
                   <a class="moreBtn b">▾▴</a>
                </td>
                <td>라이브카지노</td>
                <td>151,585,568</td>
                <td>129,802,962</td>
                <td>21,782,606</td>
                <td>1,645,441</td>
                <td>0</td>
                <td>1,645,441</td>
                <td>0</td>
             </tr>
             <tr v-if="moret">
                <td colspan="9">
                   <table class="strTable more">
                      <colgroup>
                        <col width="10%">
                        <col width="13%">
                        <col width="11%">
                        <col width="11%">
                        <col width="11%">
                        <col width="11%">
                        <col width="11%">
                        <col width="11%">
                        <col width="11%">
                      </colgroup>
                      <tr>
                        <th>세부</th>
                        <th>게임</th>
                        <th>총베팅</th>
                        <th>총당첨</th>
                        <th>베팅손익</th>
                        <th>하위 롤링P</th>
                        <th>하위 루징</th>
                        <th>내 롤링P</th>
                        <th>내 루징</th>
                      </tr>
                      <tr>
                         <td>
                           <a class="moreBtn b">▾▴</a>
                        </td>
                        <td>게임1</td>
                        <td>151,585,568</td>
                        <td>129,802,962</td>
                        <td>21,782,606</td>
                        <td>1,645,441</td>
                        <td>0</td>
                        <td>1,645,441</td>
                        <td>0</td>
                      </tr>
                   </table>
                </td>
             </tr>
             <tr>
                <td>
                  <a class="moreBtn b">▾▴</a>
               </td>
               <td>호텔카지노</td>
               <td>151,585,568</td>
               <td>129,802,962</td>
               <td>21,782,606</td>
               <td>1,645,441</td>
               <td>0</td>
               <td>1,645,441</td>
               <td>0</td>
            </tr>
            <tr>
              <td>
                 <a class="moreBtn b">▾▴</a>
              </td>
              <td>슬롯</td>
              <td>151,585,568</td>
              <td>129,802,962</td>
              <td>21,782,606</td>
              <td>1,645,441</td>
              <td>0</td>
              <td>1,645,441</td>
              <td>0</td>
           </tr>
            <tr class="total">
              <td></td>
              <td>합</td>
              <td>151,585,568</td>
              <td>129,802,962</td>
              <td>21,782,606</td>
              <td>1,645,441</td>
              <td>0</td>
              <td>1,645,441</td>
              <td>0</td>
            </tr>
           </table>
         </div>
      </div>

    </div>&lt;!&ndash; 게임별 베팅 합계 (하위 포함) &ndash;&gt;-->

    <!--div v-if="selectType === 'member'" class="memberon mb100" >
      <ul class="memberTop">
        <li>{{$t('front.stributor.onUser')}}:<span>{{loginMemList.length}}</span>{{$t('front.stributor.person')}}</li>

      </ul>
      <ul class="onmember">
        <template v-if="loginMemList.length > 0">
          <li v-for="item in loginMemList" v-bind:key="item.memIdx">
            <img src="@/assets_mobile/img/icon_rate_on.svg" alt="">
            <span class="medal medal7"></span>
            <span>{{item.memNick}}</span>
          </li>
        </template>
        <template v-else>
          <li>{{$t('front.stributor.noPerson')}}</li>
        </template>
      </ul>
    </div><-- 접속중인 회원 -->

    <!--div v-if="selectType === 'recommand'" class="recommendmember mb100" >
      <ul class="recommendTop">
        <li>{{$t('front.common.memId')}}</li>
        <li>{{$t('front.common.joinDay')}}</li>
      </ul>

      <template v-if="memList.length > 0" >
        <template v-for="(item) in memList" v-bind:key="item.memId">
          <ul class="recommendList">
            <li>{{ item.memId }}</li>
            <li>{{ dateFormatForMobile(item.regDate)}} {{$t('front.common.sign')}}<img @click="item.isDetailOpen=!item.isDetailOpen" src="@/assets_mobile/img/icon_rightarrow.svg" /></li>
          </ul>
          <div v-if="item.isDetailOpen" class="recommendInfo">
            <a @click="item.isDetailOpen=!item.isDetailOpen" class="close"><img src="@/assets_mobile/img/icon_delete.svg" /></a>
            <div class="recommendInfoDetail">
              <ul class="recommendDetail">
                <li class="bbDetail1"><span>{{$t('front.common.memId')}}</span><span>{{ item.memId }}</span></li>
                <li><span>{{$t('front.common.memId')}}</span><span>{{ item.memName }}</span></li>
                <li class="bbDetail2"><span>{{$t('front.stributor.nickName')}}</span><span>{{ item.memNick }}</span></li>
                <li class="bbDetail2"><span>{{$t('front.stributor.bottomUser')}}</span><span>{{ thousand(item.belongCnt || 0) }}</span></li>
                <li><span>{{$t('front.stributor.loginLog')}}</span><span>{{ dateFormatForMobile(item.lastLoginDt)}}</span></li>
                <li class="bbDetail2"><span>({{$t('front.stributor.noLogin')}})</span><span>(-{{item.notLoginDay}}{{$t('front.common.day')}})</span></li>
                <li><a class="moreDetail" @click="onMemberViewRate(item)">{{$t('front.stributor.rate')}} <img src="@/assets_mobile/img/icon_rightarrow.svg" /></a></li>
                <li><span>{{$t('front.stributor.deposit')}}</span><span>{{ thousand(item.inCashAmt || 0) }}</span></li>
                <li><span>{{$t('front.stributor.withdraw')}}</span><span>{{ thousand(item.outCashAmt || 0) }}</span></li>
                <li><span>{{$t('front.stributor.dnw')}}</span><span>{{ thousand( (item.inCashAmt || 0) + (item.outCashAmt || 0) ) }}</span></li>
                <li class="bbDetail2"><span>{{$t('front.common.bonus')}}</span><span>{{ thousand(item.bonusAmt || 0) }}</span></li>
                <li><a class="moreDetail" @click="onMemberViewDetail(item)">{{$t('front.stributor.detailList')}} <img src="@/assets_mobile/img/icon_rightarrow.svg" /></a></li>
                <li><span>{{$t('front.stributor.betMoney')}}</span><span>{{ thousand(item.betAmt || 0) }}</span></li>
                <li class="bbDetail2"><span>{{$t('front.stributor.winlose')}}</span><span>0</span></li>
                <li><span>{{$t('front.common.point')}}</span><span>{{ thousand(item.pointAmt || 0) }}</span></li>
                <li><span>{{$t('front.stributor.haveMoney')}}</span><span>0</span></li>
              </ul>

              <div class="moreDetailList" v-if="memberDetailList && isMemberDetail">
                <a class="close" @click="memberDetailList = {};isMemberDetail = false"><img src="@/assets/img/bkclose.png" /></a>
                <retail-detail-mobile :className="''" :list="memberDetailList"></retail-detail-mobile>
              </div>

              <div class="moreDetailList" v-if="memberRate && isMemberRate">
                <a class="close" @click="memberRate = {};isMemberRate = false"><img src="@/assets/img/bkclose.png" /></a>
                <retail-rate-mobile :rate="memberRate"></retail-rate-mobile>
              </div>
            </div>
          </div>
        </template>
      </template>

    </div><-- 추천 회원내역 -->

    <pagination-mobile :pageNum="pageInfo.page"
                       :pageSize="pageInfo.count_per_list"
                       :totalCount="pageInfo.tatal_list_count"
                       @goToPage="loadList"/>

    <!--div class="pagenamew">
      <h3 class="pagename">총판현황</h3>
    </div>
    <ul class="strhead">
      <li><img src="@/assets/img/allUserso.png" alt=""><span>전체유저</span><span class="strnum">{{thousand(summary  && summary['COUNT(*)'] || 0)}}</span></li>
      <li><img src="@/assets/img/setting_completeo.png" alt=""><span>요율 설정 완료건</span><span class="strnum">{{thousand( (summary  && summary['COUNT(*)'] || 0) - (summary.notSetCnt || 0) )}}</span></li>
      <li><img src="@/assets/img/setting_incompleteo.png" alt=""><span>요율 설정 미완료건</span><span class="strnum">{{thousand(summary  && summary.notSetCnt || 0)}}</span></li>
      <li><img src="@/assets/img/pointo.png" alt=""><span>전체 포인트</span><span class="strnum">{{thousand(summary  && summary.allpoinAmt || 0)}}</span></li>
    </ul>
    <ul class="strhead">
      <li><img src="@/assets/img/usePointo.png" alt=""><span>현재까지 사용 포인트</span><span class="strnum">{{thousand(summary  && summary.usePoinAmt || 0)}}</span></li>
      <li><img src="@/assets/img/currentPointo.png" alt=""><span>현재 나의 포인트</span><span class="strnum">{{thousand(summary  && summary.pointAmt || 0)}}</span></li>
      <li><img src="@/assets/img/progresso.png" alt=""><span>진행중인 금액</span><span class="strnum">{{thousand(summary  && summary.waitPoinAmt || 0)}}</span></li-->
<!--      <li><img src="@/assets/img/pointo.png" alt=""><span>제휴금액</span><span class="strnum">117,515,875.76</span></li>-->
    <!--/ul-->
    <!--div class="pagenamew">
      <h3 class="pagename">롤링요율</h3>
    </div>

    <retail-rate-mobile :rate="rate" /-->

    <!--div class="pagenamew">
      <h3 class="pagename">나의 상세내역</h3>
    </div>
    <date-filter-mobile :retail="true" @search="loadList"
                        @update="onChangeDate"
    />
    <div class="calcmoneywrap">
      <ul class="calcmoney">
        <li>입금:<span class="orc">{{thousand(detailInfo.inCashAmt || 0)}}</span></li>
        <li>보너스:<span class="orc">{{thousand(detailInfo.bonusAmt || 0)}}</span></li>
      </ul>
      <ul class="calcmoney">
        <li>출금:<span class="orc">{{thousand(detailInfo.outCashAmt || 0)}}</span></li>
        <li>입출금합계:<span class="orc">{{thousand((detailInfo.inCashAmt || 0) - (detailInfo.outCashAmt || 0) )}}</span></li>
      </ul>
    </div>
    <retail-detail-mobile :list="detailList"/-->

    <!--div class="pagenamew">
      <h3 class="pagename">접속중인 회원수:<span class="orc">{{loginMemList.length}}명</span><a  @click="getMyLoginMem" class="membermore">보기</a></h3>
    </div>
    <ul class="onmember">
      <template v-if="loginMemList.length > 0">
        <li v-for="item in loginMemList" v-bind:key="item.memIdx">
          <img src="@/assets/img/human.png" />
          <span>{{item.memNick}}</span>
        </li>
      </template>
      <template v-else>
        <li>접속 중인 회원이 없습니다. </li>
      </template>
    </ul-->

    <!--div class="pagenamew">
      <h3 class="pagename">추천 회원내역</h3>
    </div>
    <div class="board mb20">
      <ul class="boardstr">
        <li>번호</li>
        <li>아이디</li>
        <li>이름</li>
        <li>별명</li>
        <li>하부 유지수</li>
        <li>추천일시<br/>로그일시<br/>(미접속일수)</li>
        <li>요율보기</li>
        <li>입금액</li>
        <li>보너스</li>
        <li>출금액</li>
        <li>입출금액</li>
        <li>배팅액</li>
        <li>원루즈</li>
        <li>상세내역</li>
        <li>포인트</li>
        <li>보유금액</li>
      </ul>
      <template v-if="memList.length > 0">
        <ul class="boardstr" v-for="(item, index) in memList" v-bind:key="item.memId">
          <li>{{index + 1}}</li>
          <li>{{ item.memId }}</li>
          <li>{{ item.memName }}</li>
          <li>{{ item.memNick }}</li>
          <li>{{ thousand(item.belongCnt || 0) }}</li>
          <li>2021-07-21<br />2021-06-03<br />(-47일)</li>
          <li><a class="boardstrsw" @click="onMemberViewRate(item)">보기</a></li>
          <li>{{ thousand(item.inCashAmt || 0) }}</li>
          <li>{{ thousand(item.bonusAmt || 0) }}</li>
          <li>{{ thousand(item.outCashAmt || 0) }}</li>
          <li>{{ thousand( (item.inCashAmt || 0) + (item.outCashAmt || 0) ) }}</li>
          <li>{{ thousand(item.betAmt || 0) }}</li>
          <li>0</li>
          <li><a class="boardstrsw" @click="onMemberViewDetail(item)">보기</a></li>
          <li>{{ thousand(item.pointAmt || 0) }}</li>
          <li>0</li>
        </ul>
      </template>
      <template v-else>
      </template>

      <div class="boardstrin" v-if="memberDetailList && isMemberDetail">
        <a class="close" @click="memberDetailList = {};isMemberDetail = false"><img src="@/assets/img/bkclose.png" /></a>
        <retail-detail-mobile :className="''" :list="memberDetailList"></retail-detail-mobile>
      </div>

      <div class="boardstrin" v-if="memberRate && isMemberRate">
        <a class="close" @click="memberRate = {};isMemberRate = false"><img src="@/assets/img/bkclose.png" /></a>
        <retail-rate-mobile :rate="memberRate"></retail-rate-mobile>
      </div>
    </div>
    <pagination-mobile :pageNum="pageInfo.page"
                       :pageSize="pageInfo.count_per_list"
                       :totalCount="pageInfo.tatal_list_count"
                       @goToPage="loadList"/-->

  </section>
</template>

<script>
import NaviBar from '@/components/common/mobile/NaviBar'
import PaginationMobile from '@/components/ui/PaginationMobile'
import stributor from '@/views/member/stributor'
import DateFilterMobile from '@/components/ui/DateFilterMobile'

export default {
  name: 'stributor.vue',
  mixins: [stributor],
  components: {
    DateFilterMobile,
    NaviBar,
    PaginationMobile
  },
  data () {
    return {
      selectType: 'summary',
      memberinfo: false,
      move: false,
      make: false
    }
  }
}
</script>
<style scoped>
.ytab{max-width:750px; width:100%; height:54px;margin: 0 auto;}
.ytab>div{white-space:nowrap;overflow-x: auto;-webkit-overflow-scrolling: touch;-ms-overflow-style: -ms-autohiding-scrollbar;text-align:center}
.ytab>div::-webkit-scrollbar {display: none;}
.ytab>div>a{display:inline-block;width: 150px;font-size: 15px;color: #fff;height: 54px;line-height: 54px;background: #222;}
.ytab>div .on {border: 1px solid #333;box-sizing: border-box;}

.stributorwrap ul {padding: 7px 25px 70px;color: #fff;}
.stributorwrap li {border: 1px solid #555;border-radius: 10px;height: 77px;display: flex;justify-content: space-between;align-items: center;padding: 0 29px;margin-top: 20px;}
.stributorwrap span {font-size: 13px;}
.stributorwrap img {margin-right: 13px;vertical-align: middle;}
.stributorwrap em {font-size: 20px;font-weight: bold;color: #e5972d;margin-right: 5px;}

.mydetailTop {padding: 25px 25px 0;color: #fff;}
.havepointchng {display: flex;justify-content: space-between;align-items: center;font-size: 20px;font-weight: bold;margin-bottom: 10px;padding: 0 33px;}
.havepoint {font-size: 13px;}
.havepointchng a {font-size: 14px;background: #319e8a;padding: 3px 7px;color: #fff;border-radius: 5px;}
.mydetailTotal {font-size: 20px;font-weight: bold;padding: 0 33px;}
.prvpoint {font-size: 10px;color: #fff;background: #333;margin: 10px 0;display: block;text-align: center;padding: 10px 0;}
.havepointwrap {border-top: 1px solid #333;display: flex;align-items: center;text-align: center;}
.havepointwrap li:first-child {border-left: 0;}
.havepointwrap li {border-left: 1px solid #333;margin: 18px 0 15px;width: 100%;}
.havepointwrap li em {display: block;font-size: 13px;line-height: 14px;margin-bottom: 10px;}
.havepointwrap li p {font-weight: bold;font-size: 20px;color: #e1423c;}
.havepointwrap li:first-child p {color: #214391;}

.memberTop {background: #fafafa;display: flex;justify-content: space-between;align-items: center;padding: 7px 25px;font-size: 14px;color: #707070;}
.memberTop li:first-child span {color: #319e8a;margin: 0 3px;}
.memberTop select {border: 0;}
.medal {width: 15px;height: 15px;display: block;margin-right: 5px;}
.medal1 {background: url(~@/assets_mobile/img/level1.png) center no-repeat;background-size: 15px;}
.medal2 {background: url(~@/assets_mobile/img/level2.png) center no-repeat;background-size: 15px;}
.medal3 {background: url(~@/assets_mobile/img/level3.png) center no-repeat;background-size: 15px;}
.medal4 {background: url(~@/assets_mobile/img/level4.png) center no-repeat;background-size: 15px;}
.medal5 {background: url(~@/assets_mobile/img/level5.png) center no-repeat;background-size: 15px;}
.medal6 {background: url(~@/assets_mobile/img/level6.png) center no-repeat;background-size: 15px;}
.medal7 {background: url(~@/assets_mobile/img/level7.png) center no-repeat;background-size: 15px;}
.onmember {padding: 20px 25px 25px}
.onmember li {margin-bottom: 20px;font-size: 13px;display: flex;}
.onmember li img {vertical-align: middle;margin-right: 13px;}

.recommendTop {display: flex;background: #fafafa;color: #707070;font-size: 13px;padding: 10px 25px;}
.recommendTop li {width: 100%;}
.recommendList {display: flex;align-items: center;padding: 16px 25px;font-size: 13px;border-bottom: 1px solid #e6e6e6;}
.recommendList li {width: 100%;}
.recommendList li:first-child {font-size: 15px;}
.recommendList img {margin-left: 20px;float: right;}

.recommendInfo {position: relative;}
.recommendInfo .close {position: absolute;top: 15px;right: 25px;}
.recommendInfo .close img {height: 15px;}
.recommendInfoDetail {}
.recommendDetail {padding: 50px 25px 100px;border-bottom: 1px solid #e6e6e6;}
.recommendDetail li {display: flex;justify-content: space-between;align-items: center;font-size: 14px;margin-bottom: 5px;}
.recommendDetail li.bbDetail1 {padding-bottom: 12px;margin-bottom: 12px;border-bottom: 2px solid #e6e6e6;}
.recommendDetail li.bbDetail2 {padding-bottom: 12px;margin-bottom: 12px;border-bottom: 1px solid #e6e6e6;}
.moreDetail {margin-left: auto;color: #8e8e8e;font-size: 13px;}
.moreDetail img {margin-left: 10px;}
.moreDetailList {position: absolute;top: 0;width: 100%;background: #fff;padding: 40px 0 100px;border-top: 1px solid #e6e6e6;border-bottom: 1px solid #e6e6e6;}

.strTableWrap {max-width:1600px;width: calc(100% - 15px);margin: 15px 0 15px 15px;}
.strTableWrap>div {white-space:nowrap;overflow-x: auto;-webkit-overflow-scrolling: touch;-ms-overflow-style: -ms-autohiding-scrollbar;text-align:center}
.strTableWrap>div::-webkit-scrollbar {display: none;}
.strTable.more {/*border: 2px solid #e5972d;*/width: 100%;}
.moreT img {margin-right: 6px;}
.strTable {width: 1600px;text-align: center;vertical-align: middle;font-size: 14px;overflow: hidden;border-collapse: collapse;border-spacing: 0;}
.strTable th {height: 40px;background: #555;color: #fff;vertical-align: middle;}
.strTable td {height: 35px;
    background: #222;
    vertical-align: middle;
    border-bottom: solid 1px #333;
    border-right: 1px solid #333;
    white-space: nowrap;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;}
.strTable td:last-child {border-right: 0;}
.money {text-align: left;}
.money li {padding: 0 10px;height: 30px;display: flex;align-items: center;justify-content: space-between;}
.money li:nth-child(4) {background: #e9e9e9;}
.moreBtn {    border: solid 1px #fff;
    box-sizing: border-box;
    background-color: #222;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
    cursor: pointer;}
.moreBtn.b {width: 50px;position: relative;margin: 0 auto;}
.name {padding-left: 10px;text-align: left;display: block;}
.name em {display: block;color: #e5972d;margin-top: 5px;}
.lRoll span:first-child {margin-bottom: 5px;}
.lRoll span {display: block;text-align: left;padding-left: 5px;}
.lRoll input {width: 45px;margin: 0 3px;}

/*.strTable.more td {background: #fff3f3 !important;}
.strTable.more .strTable.more td {background: #e8ffe9 !important;}
.strTable.more .strTable.more .strTable.more td {background: #d8eaf5 !important;}
.strTable.more .strTable.more .strTable.more .strTable.more td {background: #fffee1 !important;}
.strTable.more .strTable.more .strTable.more .strTable.more .strTable.more td {background: #e1fffb !important;}
.strTable.more .strTable.more .strTable.more .strTable.more .strTable.more .strTable.more td {background: #ffe1e1 !important;}*/

.saveBtn {background: #e5972d;font-size: 14px;padding: 0 10px;height: 28px;color: #fff;line-height: 28px;text-align: center;border-radius: 5px;display: inline-block;margin: 20px 0 0 20px;}
.moveWrap {width: calc(100% - 40px);position: absolute;top: 0;left: 50%;transform: translate(-50%,0);background: #141414;padding: 0 10px 10px;border: 2px solid #333;z-index: 1;}
.moveWrap .close {position: absolute;right: 10px;top: 10px;z-index: 10;}
.moveWrap .close img {height: 30px;}
.moveWrap h4 {font-size: 16px;font-weight: bold;margin: 25px 0 15px;position: relative;color: #fff;}
.moveWrap table {width: 100%;text-align: center;font-size: 12px;}
.moveWrap th {height: 35px;background: #555;border: solid 1px #333;color: #fff;vertical-align: middle;}
.moveWrap td {height: 35px;background: #141414;vertical-align: middle;border: solid 1px #333;white-space: nowrap;color: #fff;overflow: hidden;text-overflow: ellipsis;position: relative;}
.moveWrap input {width: 35px;background: #141414;border: 1px solid #333;border-radius: 3px;font-size: 12px;color: #fff;}
.moveWrap input.in {width: 120px;height: 25px;padding: 0;}
.moveWrap select.in {width: 75px;height: 25px;background: #141414;border: 1px solid #333;border-radius: 3px;color: #fff;margin-right: 3px;}
.moveWrap p {margin-top: 15px;color: #e5972d;font-size: 11px;line-height: 1.5em;}
.moveWrap a.btn {margin-top: 15px;background: #e5972d;font-size: 14px;padding: 0 35px;height: 30px;color: #fff;line-height: 30px;text-align: center;border-radius: 5px;display: inline-block;margin-bottom: 18px;float: right;}
.rbnt {display: block;margin-bottom: 5px;}
.rbnt:first-child {margin-top: 5px;}
.error {border-color: #ff3f45 !important;}
.scroll {max-height: 300px;overflow: auto;}
.scroll table {width: 600px;}
.total td {background: #141414;}

.userBetWrap {}
</style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
